import { buildStatusMessages } from '../../../../interfaces';
import {
  LaunchTask,
  WorkStatus,
  BuildRequestStatus,
  PackageBuildInfo,
  PackageType,
  UpdateReviewStatusCommand,
  UpdateReviewStatusCommandOutput,
  UpdateReviewStatusCommandInput,
  UpdatePackageBuildInfoCommand,
  UpdatePackageBuildInfoCommandInput,
  UpdatePackageBuildInfoCommandOutput,
  GetLaunchTaskBuildRequestInfoCommand,
  GetLaunchTaskBuildRequestInfoCommandOutput,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import styles from './Packages.module.sass';
import { useAuthState } from '../../../../authentication/context';
import { useInterval } from '../../useInterval';
import { ErrorBar } from '../ErrorBar';
import { WorkStatusBadge } from '../WorkStatusBadge';
import { PublishConfirmer } from '../../../../PublishConfirmer';
import { PublishNotice } from '../../../../PublishNotice';
import { ReferenceWarning } from '../../../../ReferenceWarning';
import { Warning } from '../../../../Warning';
import { BuildNotice } from './BuildNotice';
import { ProtoPBI } from './ProtoPBI';
import { ListUpdater } from './ListUpdater';
import { PackageForm } from './PackageForm';
import { PackagesHeader } from './PackagesHeader';
import { callApi } from '../../../../client';

const { SUBMIT_FAILED, CANCELED, FAILED, DONE, SUBMIT_SKIPPED } =
  BuildRequestStatus;
const {
  PUBLISH_SCHEDULED,
  DELETED,
  TASK_RECEIVED,
  MANIFEST_BUILT,
  REVIEW_APPROVED,
} = WorkStatus;
const terminalBuildStatuses: BuildRequestStatus[] = [
  SUBMIT_FAILED,
  CANCELED,
  FAILED,
  DONE,
  SUBMIT_SKIPPED,
];
const terminalWorkStatuses: WorkStatus[] = [
  PUBLISH_SCHEDULED,
  DELETED,
  TASK_RECEIVED,
  MANIFEST_BUILT,
];

type PackageList = PackageBuildInfo[];

interface PackagesProps {
  // Starter list of packages to display
  task: LaunchTask;

  // Fired when any field is typed in
  onChange?: (items: PackageList) => void;

  // Fired when the user clicks Save
  onSave?: (items: PackageList) => void;

  // Should be fired when the future cancel button is clicked
  onCancel?: (items: PackageList) => void;

  editable?: boolean;

  onPoll?: (workStatus: WorkStatus) => void;

  publish?: boolean;

  onPublishing?: () => void;
}

const normalizePackages = (packages: PackageBuildInfo[]): ProtoPBI[] =>
  packages.map((p) => ({
    ...p,
    packageType: Object.values(PackageType).includes(p.packageType)
      ? p.packageType
      : PackageType.OTHER,
  }));
export const Packages = (props: PackagesProps) => {
  const [polling, setPolling] = useState(false);
  const [error, setError] = useState<unknown>();
  const { isAuthenticated, token } = useAuthState();
  // Current state of the package list, including modifications
  const [items, setItems] = useState<ProtoPBI[]>(
    normalizePackages(props.task.packageBuildInfoList ?? [])
  );

  const [publishNoticeVisible, setPublishNoticeVisible] = useState(false);

  const [workStatus, setWorkStatus] = useState(props.task.workStatus);
  const [buildStatus, setBuildStatus] = useState(props.task.buildRequestStatus);
  // Display validation errors for any textbox
  const [validate, setValidate] = useState(true);

  const [buildId, setBuildId] = useState(props.task.buildRequestId);

  const [taskReceived, setTaskReceived] = useState(
    props.task.workStatus === 'TASK_RECEIVED'
  );
  // Should the fields be editable
  const [editing, setEditing] = useState(props.editable ?? false);
  // const [finalizing, setFinalizing] = useState(manifestBuilt);

  const [showReferenceWarning, setShowReferenceWarning] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [headerMessage, setHeaderMessage] = useState<string>('');

  useEffect(() => {
    if (!props.publish) return;
    props.onPublishing?.();
    publish();
  }, [props.publish]);

  useEffect(() => {
    setShowReferenceWarning(
      items.some((p) => p.packageType === PackageType.DOC_MODEL)
    );

    const showZonbookWarning =
      items.some((p) => p.packageType === PackageType.GUIDE_OR_HELP_PANEL) &&
      !items.some((p) => p.packageType === PackageType.CHECKLIST);

    const hasService = items.some(
      (p) => p.packageType === PackageType.SERVICE_MODEL
    );
    const hasDoc = items.some((p) => p.packageType === PackageType.DOC_MODEL);
    const hasC2J = items.some((p) => p.packageType === PackageType.C2J);
    const hasExternal = items.some(
      (p) => p.packageType === PackageType.EXTERNAL_MODEL
    );

    const showReefWarning =
      items.some((p) => p.packageType === PackageType.API_REFERENCE_REEF) &&
      (!hasService || !hasDoc || !hasC2J);
    const showSmithyWarning =
      items.some((p) => p.packageType === PackageType.API_REFERENCE_SMITHY) &&
      (!hasService || !hasDoc || !hasExternal);

    if (showZonbookWarning) {
      setShowWarning(true);
      setHeaderMessage('Have a checklist package?');
      setWarningMessage(
        'If your guide has a Checklist package, also specify it here. Otherwise, your build will fail.'
      );
    } else if (showReefWarning) {
      setShowWarning(true);
      setHeaderMessage('Have a APIDoc package?');
      setWarningMessage(
        'Reef PackageType needs one or more service model, doc model and C2J packages'
      );
    } else if (showSmithyWarning) {
      setShowWarning(true);
      setHeaderMessage('Have a APIDoc package?');
      setWarningMessage(
        'Smithy PackageType needs one or more service model, doc model and External model packages'
      );
    } else {
      setShowWarning(false);
    }
  }, [items]);

  useEffect(() => {
    setTaskReceived(workStatus === TASK_RECEIVED);
    console.log('Approved?', workStatus === REVIEW_APPROVED);
  }, [workStatus]);

  useEffect(() => {
    console.log('buildStatus', buildStatus, 'workStatus', workStatus);
    const buildTerminal =
      !buildStatus || terminalBuildStatuses.includes(buildStatus);
    const workTerminal =
      workStatus && terminalWorkStatuses.includes(workStatus);
    const poll = !(buildTerminal && workTerminal);
    setPolling(poll);
  }, [buildStatus, workStatus]);

  useEffect(() => {
    setItems(normalizePackages(props.task.packageBuildInfoList ?? []));
    setWorkStatus(props.task.workStatus);
    setBuildStatus(props.task.buildRequestStatus);
    setBuildId(props.task.buildRequestId);
  }, [props.task]);

  useEffect(() => {
    const nowEdit = taskReceived && (props.editable ?? false);
    console.log('Now edit?', nowEdit);
    setEditing(nowEdit);
  }, [props, taskReceived]);

  const update: ListUpdater =
    (index: number) =>
    (prop: string) =>
    ({ detail }) => {
      const { value } = 'value' in detail ? detail : detail.selectedOption;
      setItems([
        // Leave other packages the same
        ...items.slice(0, index),

        // Update only the changed prop of the specified package
        { ...items[index], [prop]: value },

        // Leave other packages the same
        ...items.slice(index + 1),
      ]);
    };

  /// Fire onChange event when a field has been changed
  useEffect(() => props.onChange?.(items as PackageList), [items]);

  /**
   * save: Deactive editing and fire onSave event when Save button is clicked
   * TODO: Temporarily setValidate(true) before saving
   */
  const submit = async () => {
    if (!token) {
      alert('You are not logged in');
      return;
    }
    console.log('Saving', items);
    setEditing(false);

    // Trim whitespace from package names
    const packageBuildInfoList: PackageBuildInfo[] = items.map((pkg) => ({
      ...pkg,
      packageName: pkg.packageName.trim(),
      branch: pkg.branch.trim(),
      packageType: pkg.packageType === '' ? PackageType.OTHER : pkg.packageType,
    }));

    const updatePackageBuildInfoInput: UpdatePackageBuildInfoCommandInput = {
      launchId: props.task.launchId,
      packageBuildInfoList: packageBuildInfoList,
    };
    try {
      let response: UpdatePackageBuildInfoCommandOutput = await callApi(
        new UpdatePackageBuildInfoCommand(updatePackageBuildInfoInput),
        token
      );

      if (response.$metadata.httpStatusCode == 200) {
        setWorkStatus(WorkStatus.PACKAGE_SUBMITTED_BY_WRITER);
      } else {
        console.error(
          'Unexpected response status code:',
          response.$metadata.httpStatusCode
        );
      }
    } catch (err: any) {
      setError(err);
    } finally {
      setPolling(true);
    }
    props.onSave?.(items as PackageList);
  };

  useInterval(async () => {
    if (token && polling) {
      try {
        let res: GetLaunchTaskBuildRequestInfoCommandOutput = await callApi(
          new GetLaunchTaskBuildRequestInfoCommand({
            launchId: props.task.launchId,
          }),
          token
        );

        if (res.$metadata.httpStatusCode == 200) {
          console.log('buildRequest', res);
          const { buildRequestStatus, buildSubmitError, buildRequestId } =
            res.buildRequestInfo;
          setBuildStatus(buildRequestStatus);
          setWorkStatus(res.workStatus);
          setError(buildSubmitError);
          setBuildId(buildRequestId);
          props.onPoll?.(res.workStatus);
        } else {
          console.error(
            'Unexpected response status code:',
            res.$metadata.httpStatusCode
          );
        }
      } catch (err: any) {
        setError(err);
        setPolling(false);
      }
    }
  }, 2000);

  /**
   * addPackage: append an empty package to the list `items`
   */
  const addPackage = () =>
    setItems(
      items.concat({
        packageName: '',
        packageType: '',
        branch: '',
        commitId: '',
      })
    );

  const [confirming, setConfirming] = useState(false);

  const reject = async () => {
    if (!token) {
      alert('You are not logged in');
      return;
    }
    const updateReviewInput: UpdateReviewStatusCommandInput = {
      launchId: props.task.launchId,
      reviewStatus: 'REVIEW_REJECTED',
    };
    try {
      let response: UpdateReviewStatusCommandOutput = await callApi(
        new UpdateReviewStatusCommand(updateReviewInput),
        token
      );

      if (response.$metadata.httpStatusCode == 200) {
        setWorkStatus(WorkStatus.REVIEW_REJECTED);
      } else {
        console.error(
          'Unexpected response status code:',
          response.$metadata.httpStatusCode
        );
      }
    } catch (err: any) {
      setError(err);
    } finally {
      setPolling(true);
    }
  };

  const publish = () => {
    if (!token) {
      alert('You are not logged in');
      return;
    }
    setConfirming(true);
  };

  const publishConfirmed = async () => {
    setConfirming(false);
    setEditing(false);
    if (!token) return alert('You are not logged in');
    const updateReviewInput: UpdateReviewStatusCommandInput = {
      launchId: props.task.launchId,
      reviewStatus: 'REVIEW_APPROVED',
    };
    try {
      let response: UpdateReviewStatusCommandOutput = await callApi(
        new UpdateReviewStatusCommand(updateReviewInput),
        token
      );

      if (response.$metadata.httpStatusCode == 200) {
        setWorkStatus(WorkStatus.REVIEW_APPROVED);
        setPublishNoticeVisible(true);
      } else {
        console.error(
          'Unexpected response status code:',
          response.$metadata.httpStatusCode
        );
      }
    } catch (err: any) {
      setError(err);
    } finally {
      setPolling(true);
    }
  };
  console.log('buildStatus', buildStatus, 'workStatus', workStatus);
  return (
    <>
      <SpaceBetween size={'m'}>
        {error && (
          <ErrorBar
            errors={[
              {
                header: 'Error',
                content:
                  typeof error === 'object'
                    ? 'message' in error
                      ? (error.message as string)
                      : JSON.stringify(error)
                    : error.toString(),
              },
            ]}
          />
        )}
        <div>
          {props.editable && workStatus && (
            <>
              <span className={styles.status}>
                <WorkStatusBadge status={workStatus} />
              </span>
              <PackagesHeader
                polling={polling}
                onSubmitClick={submit}
                workStatus={workStatus}
                onPublishClick={publish}
                onAddPackageClick={addPackage}
                reject={reject}
                endpoint={props.task.reviewEndpoint}
                items={items}
              />
            </>
          )}
        </div>
        {items.map((item, i) => (
          <PackageForm
            update={update(i)}
            item={item}
            editing={editing}
            validate={validate}
            remove={() =>
              setItems([...items.slice(0, i), ...items.slice(i + 1)])
            }
          />
        ))}
        {showReferenceWarning && <ReferenceWarning />}
        {showWarning && (
          <Warning header={headerMessage} message={warningMessage} />
        )}

        <Box variant={'h2'}>
          {buildId
            ? buildStatus
              ? buildStatusMessages[buildStatus]
              : 'No status yet...'
            : buildStatus === BuildRequestStatus.SUBMIT_SKIPPED
            ? workStatus === WorkStatus.TASK_RECEIVED
              ? buildStatusMessages[BuildRequestStatus.SUBMIT_SKIPPED]
              : 'Updating...'
            : ''}
        </Box>
        {buildId && <BuildNotice buildId={buildId} />}
      </SpaceBetween>
      <PublishConfirmer
        launchId={props.task.launchId}
        onConfirm={publishConfirmed}
        onDismiss={() => setConfirming(false)}
        visible={confirming}
      />
      <PublishNotice
        launchId={props.task.launchId}
        visible={publishNoticeVisible}
        onDismiss={() => setPublishNoticeVisible(false)}
      />
    </>
  );
};
